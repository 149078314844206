<template>
  <CyModal
    :action-btn-hidden="!_.isEmpty(configRepositories) || !$cycloid.permissions.canDisplay('CreateConfigRepository')"
    action-btn-icon="add"
    :action-btn-text="$t('createConfigRepository')"
    :action-btn-func="() => $router.push({ name: 'newConfigRepository' })"
    :cancel-btn-func="() => $emit('cancel')"
    :width="554"
    :header-title="$t('createNewProject')">
    <div v-if="!_.isEmpty(configRepositories)">
      <i18n
        tag="p"
        path="newProjectDescription"
        class="mb-8">
        <a
          :href="$docLinks.project.deploy"
          class="cy-link"
          target="_blank">{{ $t('gettingStartedGuide') }}</a>
      </i18n>
      <div class="project-create__options space-x-6">
        <button
          class="project-create__option"
          :aria-label="$t('createFromStack')"
          @click="$router.push({ name: 'projectFromStack' })">
          <h3 v-text="$t('createFromStack')"/>
          <div v-text="$t('createFromStackDescription')"/>
        </button>
        <button
          class="project-create__option"
          :aria-label="$t('importYourInfrastructure')"
          @click="$router.push({ name: 'infraImport' })">
          <h3 v-text="$t('importYourInfrastructure')"/>
          <div v-text="$t('importYourInfrastructureDescription')"/>
        </button>
      </div>
    </div>
    <div v-else>
      <p v-text="$t('configRepositoryRequired')"/>
    </div>
  </CyModal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CyNewProjectModal',
  computed: {
    ...mapState('organization', {
      configRepositories: (state) => state.available.configRepositories,
    }),
  },
  i18n: {
    messages: {
      en: {
        configRepositoryRequired: 'Having a config repository is required before you can create projects. Config repositories are git repositories used to store your stacks.',
        createConfigRepository: 'Create config repository',
        createFromStack: 'Create from stack',
        createFromStackDescription: 'Use an existing stack to create a project and start adding environments in no time.',
        createNewProject: 'Create a new @:project',
        gettingStartedGuide: 'Getting started guide',
        importYourInfrastructure: 'Import your infrastructure',
        importYourInfrastructureDescription: 'Create a project and a reusable stack from an existing infrastructure.',
        newProjectDescription: 'A project is a set of environments built around a stack. Follow the {0} to get familiar with projects.',
      },
      es: {
        configRepositoryRequired: 'Es necesario tener un repositorio de configuración antes de poder crear proyectos. Los repositorios de configuración son repositorios git utilizados para almacenar tus stacks.',
        createConfigRepository: 'Crear un repositorio de configuración',
        createFromStack: 'Crear desde un stack',
        createFromStackDescription: 'Usa un stack existente para crear un proyecto y empezar a añadir entornos en un momento.',
        createNewProject: 'Crear un nuevo @:project',
        gettingStartedGuide: 'Guía de inicio',
        importYourInfrastructure: 'Importar tu infraestructura',
        importYourInfrastructureDescription: 'Crea un proyecto y un stack reutilizable a partir de una infraestructura existente.',
        newProjectDescription: 'Un proyecto es un conjunto de entornos construidos alrededor de un stack. Sigue la {0} para familiarizarte con los proyectos.',
      },
      fr: {
        configRepositoryRequired: `Il est nécessaire d'avoir un dépôt de configuration avant de pouvoir créer des projets. Les dépôts de configuration sont des dépôts git utilisés pour stocker vos stacks.`,
        createConfigRepository: 'Créer un dépôt de configuration',
        createFromStack: `Créer à partir d'une stack`,
        createFromStackDescription: `Utilisez une stack existante pour créer un projet et commencer à ajouter des environnements en un rien de temps.`,
        createNewProject: 'Créer un nouveau @:project',
        gettingStartedGuide: 'Guide de démarrage',
        importYourInfrastructure: 'Importer votre infrastructure',
        importYourInfrastructureDescription: `Créez un projet et une stack réutilisable à partir d'une infrastructure existante.`,
        newProjectDescription: `Un projet est un ensemble d'environnements construits autour d'une stack. Suivez le {0} pour vous familiariser avec les projets.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.project-create {
  &__options {
    display: flex;
    justify-content: space-between;
  }

  &__option {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    padding: 16px;
    border: 1px solid get-color("primary", "light-4");
    border-radius: 4px;
    text-align: left;
    cursor: pointer;

    h3 {
      margin-bottom: 8px;
    }

    &:hover {
      background-color: get-color("grey", "light-3");
    }
  }
}
</style>
