<template>
  <v-menu
    :min-width="_.get($attrs, 'min-width', '120')"
    :content-class="`cy-menu ${_.get($attrs, 'content-class', '')}`"
    :origin="_.get($attrs, 'origin', 'top right')"
    v-bind="$attrs"
    v-on="$listeners">
    <template #activator="{ on, value }">
      <slot
        name="activator"
        v-bind="{ on, value }">
        <CyButton
          :disabled="$attrs.disabled"
          v-bind="{
            sm: $attrs.sm,
          }"
          data-cy="menu-activator"
          variant="tertiary"
          theme="primary"
          icon="more_horiz"
          icon-only
          v-on="on"
          @click.prevent.stop/>
      </slot>
    </template>
    <slot>
      <v-list>
        <slot name="subheader"/>
        <template v-for="(item, index) in visibleItems">
          <v-divider
            v-if="item.divider"
            :key="index"/>
          <v-list-item
            v-else
            :key="index"
            v-hover-siblings
            :disabled="item.disabled"
            :class="[{
              [`cy-menu__item--${item.color}`]: item.color,
              'rainbow-hover': item.isDevItem,
            }]"
            @click="item.action">
            <v-list-item-action
              v-if="item.icon"
              class="menu__action-icon mr-2">
              <v-icon :color="item.color">
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content v-if="item.label">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </slot>
  </v-menu>
</template>

<script>
/**
 * Menu component, displayed as a "..." button.
 * Used to display a list of items, each one tied to action.
 *
 *
 * @prop {Array}      items               Array of items
 *                                        Each item can be one of:
 *                                          - { divider: true }
 *                                          - {
 *                                              ! required
 *                                              action: Function,
 *                                              label: String,
 *                                              ? optional
 *                                              color: String,
 *                                              disabled: Boolean,
 *                                              icon: String,
 *                                              isDevItem: Boolean
 *                                            }
 */

export default {
  name: 'CyMenu',
  props: {
    items: {
      type: Array,
      validator: (items) => _.isEmpty(items) || (
        _.every(items, (item) => {
          if (_.$get(item, 'divider', false)) return true
          return _.$hasAll(item, ['action', 'label']) &&
            _.isFunction(item.action)
        })
      ),
      default: () => [],
    },
  },
  computed: {
    visibleItems () {
      return this.$showDevThings
        ? this.items
        : _.filter(this.items, ({ isDevItem }) => !isDevItem)
    },
  },
  mounted () {
    const hasNoDefaultSlot = _.isEmpty(this.$slots.default)
    const hasNoItems = _.isEmpty(this.items)
    const parentName = this?.$parent?.$options?.name

    if (_.every([hasNoDefaultSlot, hasNoItems])) {
      console.error(`[CyMenu] no "default" slot content or "items" prop passed from ${parentName}. Please use either one or the other.`)
    }
  },
}
</script>

<style lang="scss" scoped>
.rainbow-hover {
  &:hover,
  &:focus {
    @extend %rainbow-background;

    .v-list-item__title,
    .v-icon {
      color: get-color("white");
      font-weight: 600;
    }

    .v-icon {
      text-shadow: none;
    }
  }
}

.cy-menu {
  ::v-deep .v-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__item--error.v-list-item:not(.v-list-item--disabled) {
    color: get-color("error") !important;

    &:hover,
    &:focus,
    &:active {
      .v-list-item__title,
      .v-icon {
        color: get-color("error");
      }
    }
  }
}

.v-menu__content {
  border: 1px solid get-color("grey");
  box-shadow: 4px 5px 15px get-color("primary", $alpha: 0.2);

  @extend .cy-menu;
}

</style>
