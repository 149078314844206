<template>
  <div
    :class="['cy-list-wrapper', {
      'cy-list-wrapper--empty': !isLoading && isEmpty,
      'cy-list-wrapper--loading': isLoading,
    }]">
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      size="100"
      color="secondary"/>

    <template v-if="!orgChanged">
      <div v-show="!isLoading && isEmpty">
        <component
          class="empty-list__image"
          :is="$static.emptyImage"/>

        <div class="empty-list__text-wrapper">
          <h5
            v-if="title"
            class="empty-list__title">
            {{ title }}
          </h5>

          <p
            v-if="text"
            class="empty-list__text mt-6 mb-9">
            {{ text }}
          </p>

          <div
            v-if="hasPermission"
            class="empty-list__actions">
            <slot name="actions"/>
          </div>
        </div>
      </div>

      <div
        v-show="!isLoading && !isEmpty"
        data-cy="content">
        <slot/>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { available } from '@/store/modules/organization'

export default {
  name: 'CyListWrapper',
  props: {
    entitiesKey: {
      type: String,
      validator: (value) => _.keys(available).includes(value),
      required: true,
    },
    createBtnText: {
      type: String,
      required: true,
    },
    hasPermission: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    orgChanged: false,
  }),
  computed: {
    ...mapGetters('organization', [
      'hasAvailable',
    ]),
    ...mapGetters('layout', [
      'getDataTableFilters',
    ]),
    $static: () => ({
      emptyImage: () => import('@/assets/images/owls/empty-chilling.svg'),
    }),
    permissionKey () {
      return this.hasPermission ? 'hasPermission' : 'noPermission'
    },
    isEmpty () {
      return !this.hasAvailable(this.entitiesKey) && this.isNotFiltering
    },
    title () {
      const entitiesName = this.$t(`${this.entitiesKey}`)
      return this.$t(`${this.permissionKey}.${this.entitiesKey}.title`, { entitiesName })
    },
    text () {
      const createBtnText = this.createBtnText
      return this.$t(`${this.permissionKey}.${this.entitiesKey}.text`, { createBtnText })
    },
    isNotFiltering () {
      return _.$isEmpty(this.getDataTableFilters(this.$route.name))
    },
  },
  watch: {
    orgCanonical () {
      this.$toggle.orgChanged(true)
    },
  },

  i18n: {
    messages: {
      en: {
        hasPermission: {
          projects: {
            title: `It looks like you don't have any @:projects yet!`,
            text: `Click on the '{createBtnText}' button to get started.`,
          },
        },
        noPermission: {
          projects: {
            title: `No @:projects found.`,
            text: `You don't have sufficient permissions to {createBtnText}.`,
          },
        },
      },
      es: {
        hasPermission: {
          projects: {
            title: `¡Parece que no tiene ningún @:projects aún!`,
            text: `Haga clic en el botón '{createBtnText}' para comenzar.`,
          },
        },
        noPermission: {
          projects: {
            title: `No se encontraron @:projects.`,
            text: `No tiene suficientes permisos para {createBtnText}.`,
          },
        },
      },
      fr: {
        hasPermission: {
          projects: {
            title: `On dirait que vous n'avez pas encore de @:projects !`,
            text: `Cliquez sur le bouton '{createBtnText}' pour commencer.`,
          },
        },
        noPermission: {
          projects: {
            title: `Aucun @:projects trouvé.`,
            text: `Vous n'avez pas suffisamment d'autorisations pour {createBtnText}.`,
          },
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-list-wrapper {
  display: flex;
  flex-direction: column;

  &--loading,
  &--empty {
    flex: 1 0 auto;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    margin-top: 1rem;
    text-align: center;
  }

  &--empty {
    @extend %dashed-border;

    .empty-list {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__image {
        max-width: 100%;
      }

      &__text-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 450px;
        margin-top: 60px;
      }

      &__title,
      &__text {
        color: get-color("primary", "light-2");
      }

      &__title {
        padding: 0 50px;
        color: get-color("primary", "light-2");
        font-size: map.get($font-sizes, "lg");
        font-weight: $font-weight-bold;
        line-height: 19px;
      }

      &__text {
        max-width: 450px;
        color: get-color("primary", "light-3");
        font-size: map.get($font-sizes, "base");
      }
    }
  }
}
</style>
