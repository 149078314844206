<template>
  <v-card
    class="cy-project-card unstyled-link"
    v-bind="cardProps"
    :disabled="loadingProjects">
    <!-- stack image -->
    <div
      class="cy-project-card-image">
      <CyTooltip bottom>
        <template #activator="{ on }">
          <img
            :src="stackImage"
            alt="stack image"
            v-on="on">
        </template>
        {{ $t('untranslated.stack') }}: <br>
        {{ _.get(project, 'service_catalog.name') }}
      </CyTooltip>
    </div>
    <div class="cy-project-card-content">
      <!-- header -->
      <div
        class="cy-project-card-header"
        data-cy="project-card-header">
        <div class="d-flex justify-start align-center">
          <h2>
            {{ project.name }}
          </h2>

          <CyTag
            v-if="!_.$isEmpty(projectImportStatus) && !isProjectImport.succeeded"
            class="cy-project-card__import-status ml-4"
            small
            :variant="isProjectImport.importing ? 'accent' : 'error'"
            @click.prevent.stop="$emit('show-import-progress-modal', project)">
            {{ projectImportStatusText }}
          </CyTag>
        </div>

        <div>
          <CyButton
            icon-only
            :icon="project.favorite ? 'star' : 'star_outline'"
            theme="primary"
            variant="tertiary"
            :loading="isSaving || loadingProjects"
            @click.prevent.stop="toggleFavorite(!project.favorite)"/>
          <CyMenu
            v-if="!_.$isEmpty($static.menuItems)"
            offset-y
            class="cy-project-card-header__menu"
            :items="$static.menuItems"/>
        </div>
      </div>
      <div class="cy-project-card-details">
        <!-- provider -->
        <span class="d-flex">
          <span
            v-for="{ name, canonical } of project._cloudProviders"
            :key="`provider-${canonical}`"
            class="cy-project-card-details__provider">
            <CyIconCredential
              :type="canonical"
              size="24"
              show-tooltip
              tooltip-direction="bottom"
              :tooltip-text="name"/>
          </span>
        </span>
        <!-- owner -->
        <CyFormsAssignOwner
          v-if="canUpdateOwner"
          :form-content="project"
          :errors="errors.project"
          :action-btn-func="assignNewOwner"
          lg
          @click.prevent.stop/>
        <CyButton
          v-else
          :to="{
            name: 'member',
            params: {
              id: project.owner.id,
              backRouteTo: 'projects',
            },
          }"
          theme="grey"
          variant="tertiary"
          lg
          member-link
          :disabled="!canGoToMember"
          :readonly="!canGoToMember"
          @click.stop>
          <CyMember
            :member="project.owner"
            simple
            sm/>
        </CyButton>
        <!-- created/updated times -->
        <span>{{ $t('created') }} {{ $date.$formatTimeAgo(project.created_at) }}</span>
        <span>{{ $t('updated') }} {{ $date.$formatTimeAgo(project.updated_at) }}</span>
      </div>
      <!-- description -->
      <p
        v-if="project.description"
        class="cy-project-card-description">
        {{ project.description }}
      </p>
      <!-- env list -->
      <div class="cy-project-card-env-list">
        <CyTagList
          contained
          :tags="project.environments">
          <template #tag="{ tag: { canonical, icon, color } }">
            <router-link
              :key="canonical"
              v-has-rights-to="['GetPipeline', `${project.canonical}-${canonical}`]"
              :to="{
                name: 'pipeline',
                params: {
                  projectCanonical: project.canonical,
                  envCanonical: canonical,
                  pipelineCanonical: `${project.canonical}-${canonical}`,
                },
              }">
              <span
                class="cy-project-card__env mr-2 d-flex align-center">
                <CyAvatar
                  :item="{ canonical, icon, color }"
                  class="mr-1"
                  sm/>
                {{ canonical }}
              </span>
            </router-link>
          </template>
        </CyTagList>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { retryInfraImportIntervalTimer } from '@/store/modules/organization/infraImport'
import CyTagList from '@/components/tag-list.vue'
import CyMenu from '@/components/menu.vue'
import CyFormsAssignOwner from '@/components/forms/assign-owner.vue'
import { displayName, hasNoOwner } from '@/utils/helpers'

export default {
  name: 'CyProjectCard',
  components: {
    CyFormsAssignOwner,
    CyTagList,
    CyMenu,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    to: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    animatedDots: '...',
    animatedDotsRefreshIntervalID: null,
    infraImportRefreshIntervalID: null,
    isSaving: false,
  }),
  computed: {
    ...mapState('organization', {
      loadingProjects: (state) => state.fetchInProgress.projects,
    }),
    ...mapState('organization/project', {
      errors: (state) => state.errors,
    }),
    ...mapGetters('organization/infraImport', [
      'infraImportStatus',
    ]),
    $static () {
      const { project: { canonical, favorite }, toggleFavorite } = this
      const { canDisplay } = this.$cycloid.permissions
      return {
        menuItems: [
          {
            icon: 'construction',
            label: this.$t('Settings'),
            action: () => {
              this.$router.push({ name: 'projectConfiguration', params: { projectCanonical: canonical } })
            },
            permissionKey: 'UpdateProject',
          },
          {
            icon: favorite ? 'star_outline' : 'star',
            label: favorite ? this.$t('removeFavorite') : this.$t('addFavorite'),
            action: () => { toggleFavorite(!favorite) },
          },
          {
            icon: 'delete',
            label: `${this.$t('forms.btnDelete')} ${this.$t('project')}...`,
            action: () => { this.$emit('delete') },
            color: 'error',
            permissionKey: 'DeleteProject',
          },
          {
            icon: 'person_add_disabled',
            label: '🦄 clear owner',
            isDevItem: true,
            action: () => { this.$emit('set-missing-owner') },
          },
        ].filter(({ permissionKey }) => !permissionKey || canDisplay(permissionKey, canonical)),
      }
    },
    cardProps () {
      const { to } = this
      return _.isEmpty(to) ? {} : { to }
    },
    isProjectImport () {
      return { [this.projectImportStatus]: true }
    },
    projectImportStatus () {
      const { infraImportStatus, project: { canonical, service_catalog: serviceCatalog } } = this

      const projectImportStatusFromLogs = infraImportStatus(canonical, 'projects')
      const importStatusFromProject = serviceCatalog?.import_status

      return projectImportStatusFromLogs || importStatusFromProject
    },
    projectImportStatusText () {
      const trailingDots = this.projectImportStatus === 'importing' ? this.animatedDots : ''
      return `${_.upperFirst(this.$t(this.projectImportStatus))}${trailingDots}`
    },
    stackImage () {
      return this.project.service_catalog?.image || '/static/images/stack_default.png'
    },
    canGoToMember () {
      const { owner } = this.project
      return !hasNoOwner(owner) && this.$cycloid.permissions.canDisplay('GetOrgMember', owner?.username)
    },
    canUpdateOwner () {
      const { canonical, owner } = this.project
      return hasNoOwner(owner) && this.$cycloid.permissions.canDisplay('UpdateProject', canonical)
    },
  },
  watch: {
    projectImportStatus: {
      handler (status) {
        window.clearInterval(this.infraImportRefreshIntervalID)
        window.clearInterval(this.animatedDotsRefreshIntervalID)

        if (status === 'importing') {
          const { canonical } = this.project || {}
          this.infraImportRefreshIntervalID = window.setInterval(() => {
            this.GET_PROJECT_INFRA_IMPORT_STATUS({ canonical })
          }, retryInfraImportIntervalTimer)
          this.animatedDotsRefreshIntervalID = window.setInterval(() => this.animateDots(), 1000)
        }
      },
      immediate: true,
    },
  },
  beforeDestroy () {
    window.clearInterval(this.infraImportRefreshIntervalID)
    window.clearInterval(this.animatedDotsRefreshIntervalID)
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    ...mapActions('organization/project', [
      'UPDATE_PROJECT',
      'SET_FAVORITE_PROJECT',
      'UNSET_FAVORITE_PROJECT',
    ]),
    ...mapActions('organization/infraImport', [
      'GET_PROJECT_INFRA_IMPORT_STATUS',
    ]),
    animateDots () {
      this.animatedDots = this.animatedDots.length > 2 ? '' : `${this.animatedDots}.`
    },
    async assignNewOwner ({ owner, formContent }) {
      const project = { ...formContent, owner }
      const successMessage = this.$t('alerts.success.project.reassigned', {
        projectName: formContent.name,
        owner: displayName(owner),
      })
      await this.UPDATE_PROJECT({ project, successMessage, refetchProject: false })
      if (_.isEmpty(this.errors.project)) this.$emit('changed-owner')
    },
    async toggleFavorite (targetState) {
      this.$toggle.isSaving(true)
      targetState
        ? await this.SET_FAVORITE_PROJECT(this.project)
        : await this.UNSET_FAVORITE_PROJECT(this.project)
      this.$toggle.isSaving(false)

      if (!_.isEmpty(this.errors.favorite)) {
        return this.SHOW_ALERT({ type: 'error', content: this.errors.favorite })
      }

      targetState
        ? this.$emit('favorited')
        : this.$emit('unfavorited')
    },
  },
  i18n: {
    messages: {
      en: {
        created: 'created',
        failed: 'import failed',
        updated: 'updated',
        addFavorite: 'Add to favorites',
        removeFavorite: 'Remove from favorites',
      },
      es: {
        created: 'creado',
        failed: 'importación fallida',
        updated: 'actualizado',
        addFavorite: 'Agregar a favoritos',
        removeFavorite: 'Eliminar de favoritos',
      },
      fr: {
        created: 'créé',
        failed: 'manqué',
        updated: 'actualisé',
        addFavorite: 'Ajouter aux favoris',
        removeFavorite: 'Supprimer des favoris',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$img-section-width: 56px;
$provider-image-size: 24px;

.cy-project-card {
  display: flex;
  flex-direction: row;
  min-height: 180px;
  padding: 24px;
  transition: all 0.3 ease-in-out;
  border-bottom: 1px solid get-color("grey", "light-2");
  border-radius: 0;
  box-shadow: none !important;
  appearance: none; // Removes Safari default button styling applied on elements with type="button"

  &:hover {
    background-color: map.get($grey, "light-3");
  }

  &-image {
    img {
      width: 48px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: $img-section-width;
    height: 100%;
    margin-top: 8px;
    margin-right: 8px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - #{$img-section-width});
    color: get-color("primary");

    & > * {
      margin-bottom: 4px;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 16px;
  }

  &-details {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;

    & > * {
      margin-right: 16px;
    }

    &__provider {
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-left: 16px;
      }
    }
  }

  &-env-list {
    margin-top: 8px;
    margin-left: 16px;
  }

  &__env {
    padding: 4px;
    border-radius: 4px;
    color: map.get($slate-grey, "light-2");
    font-weight: $font-weight-normal;

    &:hover {
      background: map.get($slate-grey, "light-4");
    }
  }

  &-description {
    margin-bottom: 8px;
    margin-left: 16px;
    color: map.get($slate-grey, "light-2");
  }
}

.default-letter {
  position: absolute;
  color: get-color("primary");
  font-size: 3em;
  font-weight: bold;
  text-shadow:
    -2px -2px 0 white,
    2px -2px 0 white,
    -2px 2px 0 white,
    2px 2px 0 white;
  text-transform: uppercase;
}

.tag {
  &--accent:hover {
    background-color: get-color("accent");
    color: get-color("accent", "light-4");
  }

  &--error:hover {
    background-color: get-color("error");
    color: get-color("error", "light-2");
  }
}
</style>
